import countries from "i18n-iso-countries";

class I18nCountries {
  constructor() {
    countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/fr.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/es.json"));
    countries.registerLocale(require("i18n-iso-countries/langs/zh.json"));
  }

  getCountries(language) {
    return countries.getNames(language);
  }
}

export default new I18nCountries();
