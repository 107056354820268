import http from "../../BaseServiceHttp";

class AlertService {
  getAlerts(factoryId) {
    return http.get(`factories/${factoryId}/alerts`);
  }
  getAlert(factoryId, alertId) {
    return http.get(`factories/${factoryId}/alerts/${alertId}`);
  }
  createAlert(factoryId, payload) {
    return http.post(`factories/${factoryId}/alerts`, payload);
  }
  deleteAlert(factoryId, alertId) {
    return http.delete(`factories/${factoryId}/alerts/${alertId}`);
  }
  updateAlert(factoryId, alertId, payload) {
    return http.put(`factories/${factoryId}/alerts/${alertId}`, payload);
  }
}

export default new AlertService();