<template>
  <article class="container-fluid">
    <h1 class="main-title">{{ $t("alerts.title") }}</h1>

    <alert-wizard v-if="!isFetchingAlert" :alert="alert" />
  </article>
</template>

<script>
import AlertWizard from "@/components/alerts/AlertWizard.vue";
import AlertService from "@/components/alerts/AlertService";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AlertView",
  components: { AlertWizard },
  data() {
    return {
      isFetchingAlert: true,
      alert: null,
    };
  },
  watch: {
    activeFactory() {
      if (this.alertId) this.fetchAlert();
    },
  },
  computed: {
    ...mapGetters("navigation", ["activeFactory"]),
    alertId() {
      return this.$route.params.id || null;
    },
  },
  methods: {
    ...mapActions("operation", ["handleHttpError"]),
    fetchAlert() {
      if (!this.activeFactory) return;
      this.isFetchingAlert = true;
      AlertService.getAlert(this.activeFactory.id, this.alertId)
        .then((httpResponse) => {
          this.alert = httpResponse.data;
          this.isFetchingAlert = false;
        })
        .catch(this.handleHttpError);
    },
  },
  mounted() {
    if (this.alertId) this.fetchAlert();
    else this.isFetchingAlert = false;
  },
};
</script>

<style scoped>

</style>